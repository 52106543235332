'use client'
import Link from 'next/link';
import blukai from '~/helpers/blukai';

export default function LinkBluKai({ children, llave, valor, ...rest }) {
  return (
    <Link
      onClick={() => {
        blukai.function(window, llave, valor);
      }}
      {...rest}
    >
      {children}
    </Link>
  );
}
